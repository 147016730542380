import domReady from '@wordpress/dom-ready';
import $ from 'jquery';

domReady(() => {
    const sliders = $('.testimonial-slider');

    function setEqualHeights() {
        const testimonialCards = $('.testimonial-slide-item');
        let heights = [];
        testimonialCards.each(function () {
            const cardHeight = $(this).outerHeight();
            heights.push(cardHeight);
        });
       
        const newHeight = Math.max(...heights);

        testimonialCards.each(function () {
            $(this).css('height', `${newHeight}px`); 
        });
    }
    setTimeout(function() {
        setEqualHeights();
    }, 2000); 

    sliders.on('init', function() {
        setTimeout(function() {
            setEqualHeights();
        }, 2000); 
    });

    // Initialize the slick slider without autoplay
    sliders.slick({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        autoplay: false, // Initially set autoplay to false
        autoplaySpeed: 1700, 
        responsive: [
            {
                breakpoint: 800, // Breakpoint for screen widths below 490px
                settings: {
                    slidesToShow: 1, // Show 1 item on mobile
                    slidesToScroll: 1 // Scroll one slide at a time
                },
            },
        ],
    });

    // Function to start autoplay
    function startAutoplay(slider) {
        slider.slick('slickSetOption', 'autoplay', false, true);
    }

    // Set up Intersection Observer to detect when the slider is in view
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // When the slider is in view, start autoplay
                startAutoplay($(entry.target));
                // Optionally, stop observing after autoplay is enabled
                observer.unobserve(entry.target);
            }
        });
    }, {
        threshold: 0.5 // Adjust threshold as needed
    });

    // Observe each slider
    sliders.each((index, slider) => {
        observer.observe(slider);
    });
});
